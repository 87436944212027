.blue-link {
  color: blue;
  text-decoration: underline;
}

table {
  border-collapse: collapse;
}

td,
th {
  border: 1px solid black;
  padding: 8px;
}

th.header-cell {
  background-color: lightgray;
}

.carousel .slider-wrapper {
  overflow: visible !important;
}

.carousel .slider-wrapper .slider .slider-frame {
  display: flex !important;
}

.carousel .slider-wrapper .slider .slider-frame .slider-slide {
  flex: 0 0 auto !important;
}
